<template>
  <div class="cms">
    <div class="container-fluid">
      <Navbar/>
      <div class="col-right" :class="{'full-width':isHideNav}">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/layout/Navbar.vue'
import { mapState } from 'vuex'

export default {
  name: 'UserCms',
  components: { Navbar },
  computed: {
    ...mapState(['isHideNav'])
  }
}
</script>

<style lang="scss">
.cms{
  background-color: $background-color;
  min-height: 100vh;
  .col-right{
    @include media-breakpoint-up(md){
      padding-left: 220px;
    }
    transition: padding 0.3s ease-in-out;
    &.full-width{
      padding-left: 0;
    }
  }
}
</style>
